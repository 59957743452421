<template>
  <div class="starter-template">
    <div class="row">
      <aside class="col-sm-4"></aside>
      <aside class="col-sm-4">
        <div class="card">
          <article class="card-body">
            <h4 class="card-title text-center mb-4 mt-1">Sign in</h4>
            <hr>
            <p class="text-danger text-center" v-if="showError">
              {{ errorMessage }}
            </p>
            <form>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                  </div>
                  <input
                      type="email"
                      name="email"
                      v-model="model.email"
                      placeholder="Enter email"
                      class="form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                  <input
                      type="password"
                      name="password"
                      v-model="model.password"
                      placeholder="Password"
                      class="form-control input-no-border"
                  />
                </div>
              </div>
              <div class="form-group">
                <button type="submit" @click.prevent="onSubmit" class="btn btn-primary btn-block"> Login</button>
              </div>
              <p class="text-center">
                <router-link class="btn" to="/forgot">Forgot password?</router-link>
                <br>
                <router-link class="btn" to="/register">Create account</router-link>
              </p>
            </form>
          </article>
        </div>
      </aside>
      <aside class="col-sm-4"></aside>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      token: localStorage.getItem("token"),
      showError: false,
      errorMessage: "",
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
    if (this.token !== null) {
      this.$router.push("/");
    }
  },
  methods: {
    onSubmit() {
      this.axios
          .post(
              window.hostname + "/auth",
              {
                username: this.model.email,
                password: this.model.password,
              },
              {headers: {"Content-Type": "application/json"}}
          )
          .then(
              function (response) {
                localStorage.setItem("token", response.data.token);
                this.$router.push("/");
              }.bind(this)
          )
          .catch(
              function (error) {
                this.showError = true;
                this.errorMessage = "Login failed. Unknown error";
                if (
                    error.response.status === 400 ||
                    error.response.status === 404
                ) {
                  this.errorMessage = "Login failed. Wrong credentials?";
                }
              }.bind(this)
          );
    },
  },
};
</script>
