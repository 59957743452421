<template>
  <div id="app">

    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <a class="navbar-brand" href="/">Sassoft Cloud</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
              aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/settings">Settings</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/profile">Profile</router-link>
          </li>

<!--          <li class="nav-item">-->
<!--            <router-link class="nav-link" to="/">Settings</router-link>-->
<!--          </li>-->

<!--          <li class="nav-item">-->
<!--            <router-link class="nav-link" to="/">Profile</router-link>-->
<!--          </li>-->

        </ul>
        <button class="navbar-brand btn btn-outline-info my-2 my-sm-0" v-on:click="doLogout">Logout</button>
      </div>
    </nav>
    <!-- Modal forms -->
    <b-modal id="modal-success" size="sm" hide-header ok-only>
      <p class="my-2">Success!</p>
    </b-modal>
    <b-modal id="modal-fail" size="sm" hide-header ok-only>
      <p class="my-2">Failed to make operation, please try later</p>
    </b-modal>
  </div>
</template>

<script>

export default {
  methods: {
    doLogout() {
      // clean local storage
      localStorage.clear()
      this.$router.push('/login');
    },
  }
}
</script>
