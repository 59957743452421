import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Forgot from '../views/Forgot.vue'
import Register from '../views/Register.vue'
import Notifications from '../views/Notifications.vue'
import Bitcoind from "../views/Bitcoind";
import Litecoind from "../views/Litecoind";
import Rates from "../views/Rates";
import Coincallback from "../views/Coincallback";
import Settings from "../views/Settings";
import Profile from "../views/Profile";
import Payment from "../views/Payment";

Vue.use(VueRouter)

const routes = [
    // Dashboard routes
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '/bitcoind',
        name: 'Bitcoind',
        component: Bitcoind
    },
    {
        path: '/litecoind',
        name: 'Litecoind',
        component: Litecoind
    },
    {
        path: '/rates',
        name: 'Rates',
        component: Rates
    },
    {
        path: '/coincallback',
        name: 'Coincallback',
        component: Coincallback
    },
    {
        path: '/payments',
        name: 'Payments',
        component: Payment
    },
    // Auth routes
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/forgot',
        name: 'Forgot',
        component: Forgot
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
