<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Notifications API
            </div>
            <div class="card-body">
              Notifications API allows you to send notifications to your users via different channels:
              <br>- Email
              <br>- Telegram (via Sassoft API Bot)
              <br>- SMTP

              <br><br> <strong>How to send?</strong>
              Example curl to make requests:

              <pre>
curl -X POST https://api.sassoft/notify -d
'{
  "message": "Hello, this is a test message",
  "message_plain": "Hello, this is a test message",
  "from": "Sassoft",
  "from_address": "hello@example.org",
  "title": "Test Message",
  "receiver": "reciever@example.org",
  "type": "email",
  "token": "your token"
}'
</pre>

              Where <i>token</i> is your API token. Note: if you use SMTP method, please put your SMTP credentials in
              Settings page.
              <br>For Telegram be sure that your user has access to Sassoft API Bot. You need to provide your Telegram
              ID in receiver field.
              <br><br>Your account <strong>has access to Notifications API.</strong>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Notifications API
            </div>
            <div class="card-body">
              <b-table id="my-table" table-class="table table-striped table-bordered" :items="notifications"
                       :fields="fields"
                       :busy="loading">
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(status)="data">
                  <template v-if="data.item.status===1">
                    <b-badge variant="success">Success</b-badge>
                  </template>
                  <template v-else>
                    <b-badge variant="danger">Failed</b-badge>
                  </template>
                </template>

                <template v-slot:cell()="data">
                  {{ data.value }}
                </template>

                <template v-slot:cell(created_at)="data">
                  {{ data.value | formatDate }}
                </template>

              </b-table>

              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  @click.native="updatePage(currentPage);"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Dashboard from '../components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      user: {},
      // table fields
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        {key: 'id', label: '#'},
        {key: 'created_at', label: 'Sent'},
        {key: 'type', label: 'Type'},
        {key: 'receiver', label: 'Receiver'},
        {key: 'message', label: 'Message'},
        {key: 'error', label: 'Error'},
        {key: 'status', label: 'Status'},
      ],
      notifications: null,
      loading: false,
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Notifications',
          href: 'notifications',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.getUserProfile()
    this.getNotifications(0)
  },
  methods: {
    getUserProfile() {
      this.axios.get(window.hostname + '/users/settings', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.user = response.data
      }).catch(error => {
        localStorage.clear()
        this.$router.push('/login')
      })
    },
    getNotifications(offset) {
      this.axios.get(window.hostname + '/users/notifications?offset=' + offset, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.notifications = response.data.items
        this.rows = response.data.total
      }).catch(error => {
        localStorage.clear()
        this.$router.push('/login')
      })
    },
    updatePage(currentPage) {
      this.getNotifications((currentPage - 1) * this.perPage)
    }
  }
}
</script>
