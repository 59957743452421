<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <div>
      <!-- Payments -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Payments
            </div>
            <div class="card-body">
              <b-table id="my-table" table-class="table table-striped table-bordered" :items="payments.items"
                       :fields="fields">
                <template v-slot:cell()="data">
                  {{ data.value }}
                </template>

                <template v-slot:cell(request)="data">
                  <textarea name="" id="" cols="55" rows="10">{{ data.value }}</textarea>
                </template>

                <template v-slot:cell(response)="data">
                  <textarea name="" id="" cols="55" rows="3">{{ data.value }}</textarea>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.value | formatDate }}
                </template>
              </b-table>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  @click.native="updatePage(currentPage);"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>


<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      payments: {
        error: "",
        total: 0,
        items: [],
      },
      payment: {
        id: 0,
        request: "",
        response: "",
        error: "",
        created_at: "",
      },
      fields: [
        {key: 'request', label: 'Request'},
        {key: 'response', label: 'Response'},
        {key: 'error', label: 'Error'},
        {key: 'created_at', label: 'Created'},
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Payments - incoming payment logs',
          href: 'Payments',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.getPayments(0)
  },
  methods: {
    getPayments(offset) {
      this.axios.get(window.hostname + '/payments?offset=' + offset, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.payments = response.data
        this.rows = response.data.total
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          this.$router.push('/login')
        }
      })
    },
    updatePage(currentPage) {
      this.getPayments((currentPage - 1) * this.perPage)
    }
  }
}
</script>
