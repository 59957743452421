<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <div>
        <coind currency="bitcoin"></coind>
    </div>
  </div>
</template>


<script>
import Dashboard from '@/components/Dashboard.vue'
import Coind from '@/components/Coind.vue'

export default {
  components: {
    Dashboard, Coind
  },
  data() {
    return {
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Bitcoind',
          href: 'bitcoind',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
  },
  methods: {}
}
</script>
