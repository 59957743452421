<template>
  <div id="coind">
    <div class="row">
      <!-- Settings -->
      <div v-if="bitcoinWallet.error!==''" class="col-lg-12">
        <div class="alert alert-danger" role="alert">
          Wallet Error: {{ bitcoinWallet.error }}
        </div>
      </div>
      <!-- Wallet balance -->
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            Wallet
          </div>
          <div class="card-body">
            <form>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Balance</label>
                    <input type="text" class="form-control" readonly v-model="bitcoinWallet.balance"/>
                  </div>
                </div>
              </div>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Unconfirmed balance</label>
                    <input type="text" class="form-control" readonly v-model="bitcoinWallet.unconfirmed_balance"/>
                  </div>
                </div>
              </div>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Wallet version</label>
                    <input type="text" class="form-control" readonly v-model="bitcoinWallet.walletversion"/>
                  </div>
                </div>
              </div>
              <br>
            </form>
          </div>
        </div>
      </div>
      <!-- Send funds -->
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            Send funds
          </div>
          <div class="card-body">
            <form>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" placeholder="Amount in BTC/LTC, min - 0.0001"
                           v-model="sendFundsForm.amount"/>
                  </div>
                </div>
              </div>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Address</label>
                    <input type="text" class="form-control" placeholder="Valid BTC/LTC address"
                           v-model="sendFundsForm.address"/>
                  </div>
                </div>
              </div>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Comment</label>
                    <input type="text" class="form-control" placeholder="Comment to receiver"
                           v-model="sendFundsForm.comment_to"/>
                  </div>
                </div>
              </div>
              <br>
            </form>
            <p class="text-danger" v-if="sendFundsForm.errorMessage!==''">
              {{ sendFundsForm.errorMessage }}
            </p>
            <p class="text-success" v-if="sendFundsForm.successMessage!==''">
              {{ sendFundsForm.successMessage }}
            </p>
            <button type="button" class="btn btn-success" @click.prevent="sendFunds">Send funds</button>
          </div>
        </div>
      </div>
      <!-- Generate address -->
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            Generate address
          </div>
          <div class="card-body">
            <form>
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">Label</label>
                    <input type="text" class="form-control" v-model="generateAddressForm.label"
                           placeholder="Attach some label to address"/>
                  </div>
                </div>
              </div>
              <br>
            </form>
            <p class="text-danger" v-if="generateAddressForm.errorMessage!==''">
              {{ generateAddressForm.errorMessage }}
            </p>
            <p class="text-success" v-if="generateAddressForm.successMessage!==''">
              {{ generateAddressForm.successMessage }}
            </p>
            <button type="button" class="btn btn-success" @click.prevent="generateAddress">Generate new address
            </button>
          </div>
        </div>
      </div>
      <!-- Service status -->
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            Wallet status
          </div>
          <div class="card-body">
            <template v-if="loaded===true">
              <template v-if="bitcoinWallet.active">
                <div class="alert alert-success" role="alert">
                  Wallet is active
                </div>
              </template>
              <template v-else>
                <div class="alert alert-danger" role="alert">
                  Wallet is disabled, check credentials: user, port, server and password.
                  <br><br> To enable wallet please provide wallet details and ask support to put password.
                </div>
              </template>
              <template v-else>
                Loading...
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <br>
    <!-- Operations -->
    <div class="row">
      <div class="col-lg-12">
        <b-table id="my-table" table-class="table table-striped table-bordered" :items="bitcoinWallet.trans"
                 :fields="fields">
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell()="data">
            {{ data.value }}
          </template>

        </b-table>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    currency: {
      type: String,
    },
  },
  data() {
    return {
      apiAddress: "",
      fields: [
        {key: 'amount', label: 'Amount'},
        {key: 'address', label: 'Address'},
        {key: 'category', label: 'Category'},
        {key: 'confirmations', label: 'Confirmations'},
        {key: 'comment', label: 'Comment'},
        {key: 'timereceived', label: 'Received'},
      ],
      loaded: false,
      bitcoinWallet: {
        balance: -1,
        unconfirmed_balance: -1,
        walletversion: -1,
        error: "",
        trans: [],
        active: false
      },
      sendFundsForm: {
        amount: 0.0001,
        address: "",
        comment_to: "",
        errorMessage: "",
        successMessage: "",
      },
      generateAddressForm: {
        label: "",
        errorMessage: "",
        successMessage: "",
      }
    }
  },
  mounted() {
    if (this.currency === "bitcoin") {
      this.apiAddress = window.hostname + '/bitcoind'
    }
    if (this.currency === "litecoin") {
      this.apiAddress = window.hostname + '/litecoind'
    }
    this.getBitcoind()
  },
  methods: {
    getBitcoind() {
      this.axios.get(this.apiAddress + '/info', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        this.bitcoinWallet = response.data
        this.loaded = true
        this.bitcoinWallet.active = true
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 400) {
          this.bitcoinWallet.active = false
        }
        if (error.response.status === 401) {
          localStorage.clear()
          this.$router.push('/login')
        }
      })
    },
    sendFunds() {
      this.sendFundsForm.successMessage = ""
      this.sendFundsForm.errorMessage = ""
      this.axios
          .post(
              this.apiAddress + '/transfer',
              {
                address: this.sendFundsForm.address,
                amount: this.sendFundsForm.amount,
                comment: "Sent from Sassoft Cloud",
                comment_to: this.sendFundsForm.comment_to,
              },
              {
                headers: {
                  Token: localStorage.getItem('apiToken')
                }
              }
          )
          .then(
              function (response) {
                if (response.data.error !== "") {
                  this.sendFundsForm.errorMessage = "Failed! " + response.data.error
                  return
                }
                this.sendFundsForm.successMessage = "Successfully sent! TXID: " + response.data.txID
                this.sendFundsForm.errorMessage = ""
                this.sendFundsForm.comment_to = ""
                this.sendFundsForm.amount = 0
                this.sendFundsForm.address = ""
              }.bind(this)
          )
          .catch(error => {
                if (error === null) {
                  this.sendFundsForm.errorMessage = "Unknown error"
                  return
                }
                this.sendFundsForm.errorMessage = error.response.data.error
                if (error.response.status === 401) {
                  localStorage.clear()
                  this.$router.push('/')
                }
              }
          )
    },
    generateAddress() {
      this.generateAddressForm.successMessage = ""
      this.generateAddressForm.errorMessage = ""
      this.axios
          .post(
              this.apiAddress + '/address',
              {
                label: this.generateAddressForm.label,
              },
              {
                headers: {
                  Token: localStorage.getItem('apiToken')
                }
              }
          )
          .then(
              function (response) {
                if (response.data.error !== "") {
                  this.generateAddressForm.errorMessage = "Failed! " + response.data.error
                  return
                }
                this.generateAddressForm.successMessage = "Successfully created! Address: " + response.data.address
                this.generateAddressForm.errorMessage = ""
                this.generateAddressForm.label = ""
              }.bind(this)
          )
          .catch(error => {
                if (error === null) {
                  this.generateAddressForm.errorMessage = "Unknown error"
                  return
                }
                this.generateAddressForm.errorMessage = error.response.data.error
                if (error.response.status === 401) {
                  localStorage.clear()
                  this.$router.push('/')
                }
              }
          )
    },
  }
}
</script>
