<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <!-- Settings, Service status -->
    <div class="row">
      <!-- Settings -->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            Settings
          </div>
          <div class="card-body">
            <form>
              <!-- API token -->
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">API Token</label>
                    <input type="text" class="form-control" readonly v-model="user.token"/>
                  </div>
                </div>
              </div>

              <!-- BTC -->
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">BTC Server</label>
                    <input type="text" name="btc_server" v-model="user.btc_server" class="form-control"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">BTC Port</label>
                    <input type="text" name="btc_port" class="form-control" v-model="user.btc_port"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">BTC User</label>
                    <input type="text" name="btc_user" class="form-control" v-model="user.btc_user"/>
                  </div>
                </div>
              </div>

              <!-- XRP -->
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">XRP Server</label>
                    <input type="text" name="btc_server" v-model="user.xrp_server" class="form-control"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">XRP Port</label>
                    <input type="text" name="btc_port" class="form-control" v-model="user.xrp_port"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">XRP Account</label>
                    <input type="text" name="btc_user" class="form-control" v-model="user.xrp_account"/>
                  </div>
                </div>
              </div>

              <!-- LTC -->
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">LTC Server</label>
                    <input type="text" v-model="user.ltc_server" class="form-control"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">LTC Port</label>
                    <input type="text" class="form-control" v-model="user.ltc_port"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">LTC User</label>
                    <input type="text" class="form-control" v-model="user.ltc_user"/>
                  </div>
                </div>
              </div>

              <!-- SMTP -->
              <div class="row mb-">
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">SMTP Host</label>
                    <input type="text" v-model="user.smtp_host" class="form-control"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">SMTP Port</label>
                    <input type="text" class="form-control" v-model="user.smtp_port"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <label class="form-label">SMTP User</label>
                    <input type="text" class="form-control" v-model="user.smtp_username"/>
                  </div>
                </div>
              </div>
              <br>
            </form>
            <button type="button" class="btn btn-primary" @click.prevent="updateProfile">Save settings</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      user: {
        username: '',
        telegram_id: '',

        btc_server: '',
        btc_port: '',
        btc_user: '',

        xrp_server: '',
        xrp_port: '',
        xrp_account: '',

        ltc_server: '',
        ltc_port: '',
        ltc_user: '',

        smtp_host: '',
        smtp_port: 0,
        smtp_username: '',

        control_accounts: '',
      },
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Settings',
          href: 'settings',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.initAll()
  },
  methods: {
    initAll() {
      this.getUserProfile()
    },
    updateProfile() {
      this.axios
          .put(
              window.hostname + '/users/settings',
              {
                btc_server: this.user.btc_server,
                btc_port: this.user.btc_port,
                btc_user: this.user.btc_user,
                xrp_server: this.user.xrp_server,
                xrp_port: this.user.xrp_port,
                xrp_account: this.user.xrp_account,
                ltc_server: this.user.ltc_server,
                ltc_port: this.user.ltc_port,
                ltc_user: this.user.ltc_user,
                smtp_host: this.user.smtp_host,
                smtp_port: parseInt(this.user.smtp_port, 10),
                smtp_username: this.user.smtp_username
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
          )
          .then(
              function (response) {
                this.$bvModal.show("modal-success")
                // invalidate internal storage
                localStorage.removeItem('user')
              }.bind(this)
          )
          .catch(error => {
                this.$bvModal.show("modal-fail")

                if (error.response.status === 401) {
                  localStorage.clear()
                  this.$router.push('/')
                }
              }
          )
    },
    getUserProfile() {
      return this.axios.get(window.hostname + '/users/settings', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.user = response.data
        if (this.user.control_accounts !== "") {
          this.user.control_accounts = JSON.parse(response.data.control_accounts)
        }
        localStorage.setItem("apiToken", response.data.token);
      }).catch(error => {
        localStorage.clear()
        this.$router.push('/login')
      })
    }
  }
}
</script>
