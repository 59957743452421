<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <div>
      <!-- Active addresses -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Active addresses
            </div>
            <div class="card-body">
              <b-table id="my-table" table-class="table table-striped table-bordered" :items="addresses"
                       :fields="addressesFields">
                <template v-slot:cell()="data">
                  {{ data.value }}
                </template>

                <template v-slot:cell(action)="data">
                  <b-button variant="danger" v-on:click="doAddressRemove(data.item.address)">Remove</b-button>
                </template>

              </b-table>
            </div>
          </div>
        </div>
      </div>

      <!-- Sent webhooks -->
      <br>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Sent webhooks
            </div>
            <div class="card-body">
              <b-table id="my-table" table-class="table table-striped table-bordered" :items="webhooks"
                       :fields="webhookFields">
                <template v-slot:cell()="data">
                  {{ data.value }}
                </template>

                <template v-slot:cell(created_at)="data">
                  {{ data.value | formatDate }}
                </template>

              </b-table>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  @click.native="updatePage(currentPage);"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <br>
      <!-- Service information -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Coincallback API
            </div>
            <div class="card-body">
              Coincallback API provides you ability to receive webhooks once you get funds in the specific
              cryptocurrency
              address.
              <hr>
              <strong>How to send address to coincallback?</strong>
              <br>Example curl to make requests:
              <pre>curl -X POST https://api.sassoft.ru/coincallback/address \
  -d '{"address":"address","currency":"btc","confirmations":10,"callbackUrl":"https://example.com/webhook","token":"your api token"}'</pre>
              Where:
              <br><i>token</i> is your API token.
              <br><i>address</i> is cryptocurrency address which you need to get notifications once you get funds.
              <br><i>currency</i> is cryptocurrency address. We support: btc, ltc, eth, xrp, doge, zec.
              <br><br>Example of success response:
              <pre>
{
 "status": "1",
 "error": ""
}
            </pre>
              <hr>
              <strong>How to delete address from coincallback?</strong>
              <br>Example curl to make requests:
              <pre><br>Example curl to make requests:
              <pre>curl -X DELETE https://api.sassoft.ru/coincallback/address/_address_ \
  -d '{"token":"your api token"}'</pre></pre>
              Where:
              <br><i>token</i> is your API token.
              <br><i>_address_</i> is cryptocurrency address which you already pushed to coincallback.
              <br><br>Example of success response: 204 No content
              <hr>
              Your account <strong>has access to Coincallback API.</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      addresses: [],
      addressesFields: [
        {key: 'currency', label: 'Currency'},
        {key: 'address', label: 'Address'},
        {key: 'url', label: 'URL'},
        {key: 'confirmations', label: 'Confirmations'},
        {key: 'action', label: 'Action'},
      ],
      webhooks: [],
      webhookFields: [
        {key: 'created_at', label: 'Date'},
        {key: 'url', label: 'URL'},
        {key: 'request', label: 'Request'},
        {key: 'response', label: 'Response'},
        {key: 'code', label: 'HTTP code'},
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Coincallback',
          href: 'Coincallback',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.getActiveAddresses()
    this.getSentWebhooks(0)
  },
  methods: {
    doAddressRemove(address) {
      this.axios.delete(window.hostname + '/coincallback/address/' + address, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          token: localStorage.getItem('apiToken'),
        },
      }).then(response => {
        this.$bvModal.show("modal-success")
        this.getActiveAddresses()
      }).catch(error => {
        if (error.response.status === 401) {
          localStorage.clear()
          this.$router.push('/login')
          return
        }
        this.$bvModal.show("modal-fail")
      })
    },
    getActiveAddresses() {
      this.axios.get(window.hostname + '/coincallback/address', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.addresses = response.data
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          this.$router.push('/login')
        }
      })
    },
    getSentWebhooks(offset) {
      this.axios.get(window.hostname + '/coincallback/webhooks?offset=' + offset, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.webhooks = response.data.items
        this.rows = response.data.total
      }).catch(error => {
        if (error.response.status === 401) {
          localStorage.clear()
          this.$router.push('/login')
        }
      })
    },
    updatePage(currentPage) {
      this.getSentWebhooks((currentPage - 1) * this.perPage)
    }
  }
}
</script>
