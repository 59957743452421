<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              Rates API
            </div>
            <div class="card-body">
              Rates API provides you latest crypto and fiat assets in json format.
              <br>We take data from coincap.io and give you rates in three major currencies: USD, EUR, RUR.
              <br><br> <strong>How to get data?</strong>
              Example curl to make requests:
              <pre>curl -X GET -H "Token: token" https://api.sassoft/rates</pre>
              Where <i>token</i> is your API token.
              <br><br>Example response:
              <pre>
{
 "BTC_USD": 57424.64339465961,
 "BTC_EUR": 48105.59999064407,
 "ETH_USD": 1777.7279741810603
 ... ... ...
}
            </pre>
              Your account <strong>has access to Rates API.</strong>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>


<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Rates',
          href: 'Rates',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
  },
  methods: {}
}
</script>
