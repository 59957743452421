<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <!-- Events -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            Last Events
          </div>
          <div class="card-body">
            <b-table id="my-table" table-class="table table-striped table-bordered" :items="events.items"
                     :fields="fields">
              <template v-slot:cell()="data">
                {{ data.value }}
              </template>

              <template v-slot:cell(created_at)="data">
                {{ data.value | formatDate }}
              </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                @click.native="updateEventsPage(currentPage);"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <br>
    <!-- Notifications, Rates, Coincallback, Payments -->
    <div class="row">
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            <router-link to="/notifications">Notifications API</router-link>
          </div>
          <div class="card-body">
            Status: <strong>true</strong>
            <br>Sent today: {{ notifications.today }}
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            <router-link to="/rates">Rates API</router-link>
          </div>
          <div class="card-body">
            Status: <strong>true</strong>
            <br>Rates API is enabled
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            <router-link to="/coincallback">Coincallback API</router-link>
          </div>
          <div class="card-body">
            Status: <strong>true</strong>
            <br>Active callbacks: No data
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-header">
            <router-link to="/payments">Payments</router-link>
          </div>
          <div class="card-body">
            Success today: <strong>{{ paymentsToday }}</strong>
            <br>Error today: <strong>0</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      user: {},
      bitcoinWallet: {
        balance: -1,
        unconfirmed_balance: -1,
        walletversion: -1,
        error: "",
        trans: [],
        active: false
      },
      litecoinWallet: {
        balance: -1,
        unconfirmed_balance: -1,
        walletversion: -1,
        error: "",
        trans: [],
        active: false,
      },
      rippleWallet: {
        balance: -1,
        account: "",
        validated: "",
        status: true,
        trans: [],
        active: false,
      },
      cpingNotifications: {
        error: "",
        total: 0,
        items: [],
      },
      notifications: {
        today: 0,
        yesterday: 0,
      },
      monitors: {
        total: 0,
        totalOK: 0,
        totalFail: 0,
      },
      // Events variables ---
      events: {
        error: "",
        total: 0,
        items: [],
      },
      fields: [
        {key: 'created_at', label: 'Date'},
        {key: 'service', label: 'Service'},
        {key: 'log', label: 'Log'},
      ],
      currentPage: 1,
      perPage: 5,
      rows: 0,
      // End ---
      flagsEnabled: 0,
      flagsDisabled: 0,
      paymentsToday: 0,
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.initAll()
  },
  methods: {
    initAll() {
      this.getUserProfile()
      // this.getBitcoind()
      // this.getLitecoind()
      // this.getRippled()
      // this.getCpingNotifications()
      this.getNotificationStats()
      // this.getMonitors(0)
      // this.getFlags()
      this.getPayments()
      this.getEvents(0)
    },
    getUserProfile() {
      return this.axios.get(window.hostname + '/users/settings', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.user = response.data
        // if (this.user.control_accounts.trim() !== "") {
        this.user.control_accounts = JSON.parse(response.data.control_accounts)
        // }
        localStorage.setItem("apiToken", response.data.token);
      }).catch(error => {
        localStorage.clear()
        console.log('getUserProfile error: ' + error)
        this.$router.push('/login')
      })
    },
    getNotificationStats() {
      this.axios.get(window.hostname + '/users/notifications/stats', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.notifications.today = response.data.total_today
        this.notifications.yesterday = response.data.total_yesterday
      }).catch(error => {
        localStorage.clear()
        console.log('getNotificationStats error: ' + error)
        this.$router.push('/login')
      })
    },
    // Stats dashboard
    getMonitors(offset) {
      this.axios.get(window.hostname + '/monitors?offset=' + offset, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.monitors.total = response.data.total
        this.monitors.totalOK = response.data.total_ok
        this.monitors.totalFail = response.data.total_fail
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getMonitors error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getBitcoind() {
      this.axios.get(window.hostname + '/bitcoind/info', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        this.bitcoinWallet = response.data
        // this.loaded = true
        if (this.bitcoinWallet.balance >= 0) {
          this.bitcoinWallet.active = true
        }
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 400) {
          this.bitcoinWallet.active = false
        }
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getBitcoind error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getLitecoind() {
      this.axios.get(window.hostname + '/litecoind/info', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        this.litecoinWallet = response.data
        this.loaded = true
        if (this.litecoinWallet.balance >= 0) {
          this.litecoinWallet.active = true
        }
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 400) {
          this.litecoinWallet.active = false
        }
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getLitecoind error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getRippled() {
      this.axios.get(window.hostname + '/rippled/info', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        this.rippleWallet = response.data
        this.loaded = true
        if (this.rippleWallet.balance >= 0) {
          this.rippleWallet.active = true
        }
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 400) {
          this.rippleWallet.active = false
        }
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getRippled error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getCpingNotifications() {
      this.axios.get(window.hostname + '/cping/notifications', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        this.cpingNotifications = response.data
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getCpingNotifications error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getFlags() {
      this.axios.get(window.hostname + '/flags', {
        headers: {
          Token: localStorage.getItem('apiToken')
        }
      }).then(response => {
        for (const flagsKey in response.data) {
          if (response.data[flagsKey].enabled == true) {
            this.flagsEnabled++
          } else {
            this.flagsDisabled++
          }
        }
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getFlags error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getPayments() {
      this.axios.get(window.hostname + '/payments-stats', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.paymentsToday = response.data.total
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getPayments error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    getEvents(offset) {
      this.axios.get(window.hostname + '/users/events?limit=5&offset=' + offset, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.events = response.data
        this.rows = response.data.total
      }).catch(error => {
        this.loaded = true
        if (error.response.status === 401) {
          localStorage.clear()
          console.log('getEvents error: ' + error)
          this.$router.push('/login')
        }
      })
    },
    updateEventsPage(currentPage) {
      this.getEvents((currentPage - 1) * this.perPage)
    }
  }
}
</script>
