<template>
  <div class="starter-template">
    <Dashboard></Dashboard>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <!-- Change password, Telegram integration -->
    <div class="row">
      <!-- Settings -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            Settings
          </div>
          <div class="card-body">
            <form>
              <div>
                <div class="form-group has-label" aria-required="true" aria-invalid="true">
                  <label>Current password</label>
                  <input aria-describedby="addon-right addon-left" name="current_password"
                         type="password"
                         v-model="current_password"
                         placeholder="Current password" required
                         class="form-control">
                </div>
                <div class="form-group has-label" aria-required="true" aria-invalid="true">
                  <label>New password</label>
                  <input aria-describedby="addon-right addon-left" name="new_password"
                         type="password"
                         v-model="new_password"
                         placeholder="Add new password" required
                         class="form-control">
                </div>
                <div class="form-group has-label" aria-required="true" aria-invalid="true">
                  <label>Confirm new password</label>
                  <input aria-describedby="addon-right addon-left" name="new_password_confirm"
                         type="password"
                         v-model="new_password_confirm"
                         placeholder="Confirm password" required
                         class="form-control">
                </div>
                <div class="text-center">
                  <p class="text-danger text-center" v-if="showError">
                    {{ errorMessage }}
                  </p>
                  <button type="button" @click.prevent="updatePassword" class="btn btn-info btn-fill float-left">Update
                    Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Telegram integration -->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            Notification settings
          </div>
          <div class="card-body">
            <form>
              <div>
                <div class="form-group has-label" aria-required="true" aria-invalid="true">
                  <label>Primary email</label>
                  <input aria-describedby="addon-right addon-left" name="email"
                         type="text" disabled
                         v-model="user.username"
                         placeholder="Primary email"
                         class="form-control">
                </div>
                <div class="form-group has-label" aria-required="true" aria-invalid="true">
                  <label>Telegram Account [<a :href="url" target="_blank">Add/Update Telegram Bot</a>]</label>
                  <input aria-describedby="addon-right addon-left" name="telegram_id"
                         type="text"
                         readonly
                         v-model="user.telegram_id"
                         placeholder="Telegram Account ID"
                         class="form-control">
                </div>
                <div class="text-center">
                  <p class="text-danger text-center" v-if="showError">
                    {{ errorMessage }}
                  </p>
                  <!--                  <button type="button" @click.prevent="updatePassword" class="btn btn-info btn-fill float-left">Update-->
                  <!--                    Notification Settings-->
                  <!--                  </button>-->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      url: '',
      current_password: '',
      new_password: '',
      new_password_confirm: '',
      errorMessage: "",
      showError: false,
      user: {
        username: '',
        token: '',
        telegram_id: '',
      },
      items: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Profile',
          href: 'profile',
          active: true
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.initAll()
  },
  methods: {
    initAll() {
      this.getUserProfile()
    },
    updatePassword() {
      this.showError = false
      this.errorMessage = ""
      this.axios
          .put(
              window.hostname + '/users/password',
              {
                current_password: this.current_password,
                new_password: this.new_password,
                new_password_confirm: this.new_password_confirm,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
          )
          .then(
              function (response) {
                this.$bvModal.show("modal-success")
                this.current_password = ""
                this.new_password = ""
                this.new_password_confirm = ""
              }.bind(this)
          )
          .catch(error => {
                this.showError = true
                this.errorMessage = error.response.data.error
                if (error.response.status === 401) {
                  localStorage.clear()
                  this.$router.push('/')
                }
              }
          )
    },
    getUserProfile() {
      return this.axios.get(window.hostname + '/users/settings', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => {
        this.user = response.data
        if (this.user.control_accounts !== "") {
          this.user.control_accounts = JSON.parse(response.data.control_accounts)
        }
        localStorage.setItem("apiToken", response.data.token);
        this.url = 'https://telegram.me/sassoft_notifications_bot?start=' + this.user.token
      }).catch(error => {
        localStorage.clear()
        this.$router.push('/login')
      })
    }
  }
}
</script>
